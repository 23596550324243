


.about-img img{
  width: 100%;
}
.portfolio{
  border: 1px rgb(0, 0, 0);
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 40px;
  box-shadow: 0 4px 16px rgba(244, 239, 239, 0.1);
  border-radius: 5px;
  height: 450px;
  background-color: #00000029;
 
}
.portfolio h2{
text-align: center;
margin-top: 20px;
color: #c1c1c2;
}
.portfolio h5{
text-align: center;
margin-top:20px;
color: #dddbdb;
}
a{
  text-decoration: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
}


.portfolio:hover{
  box-shadow: 0 4px 16px rgba(20, 20, 20, 0.595);
}

















