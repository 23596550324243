.img-card{
    margin-top: 60px;
    background-color: #f6f9fe;
}
.img-card-q button{
    color: #106eea;
    width: 95px;
     text-align: center;
   margin-top:20px;
    border-style: none;
    border-radius: 30px;
   font-size: 13px;
   font-weight: 700;
     background: #e7f1fd;
    color: #106eea;
    display: inline-block;
    border-radius: 50px;
    padding: 10px;
}
.btn-info{
    color: #fff !important;
    width: 150px;
    border-style: none;
    margin-top: 50px;
    background-image: linear-gradient(90deg, rgb(101, 97, 166) 0%, rgb(127, 127, 234) 35%, rgb(0, 109, 131) 100%);
    padding: 10px;
}

.img-card-q span{
font-size: 17px;
color: #fff;
}
.img-card-q h2{
    font-size: 38px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.img-card-q{
    
    text-align: center;
    margin-bottom: 20px;
}
.contact-sec{
    padding-bottom: 30px;
}
.contact-sec h3{
  color: #fff;
}
.contact-sec p{
    color: #fff;
}
.contact{
    margin-bottom: 20px;
    box-shadow: 0 4px 16px rgba(250, 250, 250, 0.1);
    border: 1px rgb(229, 11, 11);
    padding: 80px;
}
@media only screen and (max-width: 768px) {
    .contact{
        padding: 25px 10px 25px 10px ;
    }
  }


