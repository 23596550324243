.navbar-expand .navbar-nav .nav-link{
    color:#fff;
}
.nav-link{
    color:#fff;
}
.bg{
    background-color: #000;
}

.nav-link {
    color: #fff;
}
.nav-link:hover {
    color: #fff;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: rgb(255 255 255);
}
.menu{
   background-color: #fff;
   position: absolute;
   top: 10px;
   right: 20px;
   
   
}
.me-auto{

    display: flex;
    justify-content: flex-end;
    gap:20px;

  }
.logo_menu{
    width: 15%;
}
 .active{
    color: #ee5b28!important;
}

@media only screen and (max-width: 600px) {

    .logo_menu{
        width: 50%;
    }
   
   
    }
    @media only screen and (max-width: 991px) {

        
        .logo_menu{
            width: 40%;
        }
       
        }
