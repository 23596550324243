
   
    .footer-sec{
      margin-top: 50px;
      padding-top: 20px;
        background-color: #000000;
        color: #ffff;
    }
    .footer{
    border-bottom:1px solid gray ;
    margin-bottom: 30px;
    margin-top: 20px;
    }
    .footer i{
      color: orangered;
      margin: 5px;
      font-size: 26px;
    }
    .footer p{
      color: #fff;
    margin-left: 10px;
    padding-bottom: 10px;
    }
    .footer span{
      font-size: 25px;
    }
    .footer-page{
      padding: 20px 0px 20px 0px;
    }
    .footer-page img{
      width: 211px;
    
    }
    .footer-page span ul li{
    list-style: none;
    display: inline;
    font-family: math;
    
    }
    .footer-page span ul{
     
      position: relative;
      left: -52px;
     
    }
    .footer-page .fa-facebook{
     height: 39px;
     width: 39px;
      font-size: 20px;
      border: 1px solid blue;
      border-radius: 50%;
      text-align: center;
      padding-top: 10px;
      box-shadow: #4154f1;
      background-color: #4154f1;
      border-style: none;
    }
    .footer-page .fa-instagram{
      padding: 10px;
      margin: 10px;
      font-size: 20px;
      border: 1px solid blue;
      border-radius: 200px;
      box-shadow: #4154f1;
      background-color: orangered;
      border-style: none;
    }
    
    .footer-page .fa-youtube-play{
      padding: 10px;
      margin: 10px;
      font-size: 20px;
      border: 1px solid blue;
      border-radius: 200px;
      box-shadow: #4154f1;
      background-color: #6e7bf8;
      border-style: none;
    }
    .footer-icon ul li{
      list-style: none;
      font-size: 17px;
      
    }
    
    
    .footer-page p{
     color: #fff;
      margin-top: 20px;
      
      font-family: math;
    }
    .footer-page h3{
      margin-top: 20px;
      margin-left: 10px;
    }
    
    .footer-page input{
      width: 270px;
    padding: 10px;
    outline: none;
    border-style: none;
    
    }
    .usefull-link{
      font-size: 25px;
    }
    .footer-page button{
      background-color: orangered;
      width: 60px;
      padding: 11px;
      color: #fff !important;
      border-style: none;
    }
    .center{
      text-align: left;
      cursor: pointer;
    }
    
    @media only screen and (max-width: 600px) {
     
    }
  
    
    