
.blog-imge img{
  width: 100%;
}
 .blog-img-section{
   margin: 60px;
 }
 .blog-img-section .blog-contain{
   
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 }
 .blog-img-section .blog-contain h2{
   color: white;
   margin-top: 15px;
   padding: 15px;
 }
 .blog-img-section .blog-contain p{
   color: white;
   margin-top: 15px;
   justify-content: center;
   font-size: 14px;
  padding: 20px;
 }
 .blog-img-section .blog-contain p b{
   font-size: 18px;
   font-weight: 600;
 }
 .blog-img-section .blog-contain ul li{
 color: white;
 text-decoration: none;
 display: inline;
 padding: 15px;
 }
 .blog-containn{
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   margin-top: 25px;
 }
 .blog-img-section .blog-containn h2{
   color: white;
   margin-top: 15px;
   padding: 15px;
 }
 .blog-img-section .blog-containn p{
   color: white;
   margin-top: 15px;
   justify-content: center;
   font-size: 14px;
  padding: 20px;
 }
 .blog-img-section .blog-containn ul li{
   color: white;
   text-decoration: none;
   display: inline;
   padding: 15px;
   }
   .blog-search h3{
     color: white;
     font-size: 20px;
     margin-top: 10px;
   }
   .blog-search input{
     width: 61%;
     height: 34px;
     border: none;
     position: relative;
   }
   .blog-search{
    
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     padding: 20px;
   }
   .blog-search button{
     margin-left: 5px;
     padding-right: 12px;
     padding-left: 12px;
     padding-bottom: 7px;
     background-color: #4963ba;
     color: white;
     border: none;
   }
   .blog-search p{
     color: white;
     margin-top: 10px;
     font-size: 14px;
   }
   .blog-search ul li{
     color: white;
     font-size: 14px;
     list-style: none;
     margin-top: 5px;
   }
   #post-img{
     margin-top: 10px;
   }
 .img-card{
   margin-top: 60px;
 }
 .img-card-q button{
   color: #106eea;
   width: 95px;
    text-align: center;
  margin-top:20px;
   border-style: none;
   border-radius: 30px;
  font-size: 13px;
  font-weight: 700;
    background: #e7f1fd;
   color: #106eea;
   display: inline-block;
   border-radius: 50px;
   padding: 10px;
 }
 
 .img-card-q span{
 font-size: 17px;
 }
 .img-card-q{
   text-align: center;
   margin-bottom: 20px;
 }
 
 .img-w{
 
   background-color: #ffffff;
   position: relative;
  
  }
  .img-w h5{
   margin-top: 10px;
   margin-left: 10px;
   font-size: 17px;
   color: black;
  }
  .img-w p{
      font-size: 15px;
      margin-left: 12px;
      color: #0d0101;
  }
  .img-w ul li{
   list-style: none;
  
   padding: 10px;
   font-size: 18px;
   color: #080000;
   background-color: #106eea;
   display: inline;
   margin: 5px;
   overflow: hidden;
   display: none;
   opacity: 0;
 
  }
  .blog-search h5{
   margin-left: 30px;
   margin-top: 10px;
   color: #ffff;
  }
 
 
  .blog-search ul li{
   color: #e4e6ea;
   font-size: 14px;
   padding: 6px 14px;
   margin: 21px 7px 13px 14px;
   border: 1px solid #d7e6ff;
   display: inline-block;
   transition: 0.3s;
 }
  .img-w ul{
   position: absolute;
   bottom: 70px;
   right: 10px;
  
 }
 .img-w:hover ul li{
 display: block;
 opacity: 1;
 transition: opacity 200ms, display 200ms;
 }
 
 .img-card{
   color: #e4e9ee;
   background: none !important;
 }
 
 
 .pg7{
   padding-top: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 .test-head{
   color: #fff;
   
 }
 .test-head>h1{
   font-size: 60px;
   font-weight: 400;
 }
 
 .testimonials {
   max-width: 700px;
   width: 100%;
   display: grid;
   grid-template-columns: 130px 1fr;
   margin-top: 60px;
 }
 .testi-img{
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 20px;
 }
 .testi-img>img{
   height: 80px;
   width: 80px;
   border-radius: 100%;
   border: 4px solid #1f41ba;
 }
 
 .testimonials-badge {
   width: 13px;
   height: 13px;
   border: 1px solid #ddd !important;
   border-radius: 90px;
   cursor: pointer;
 }
 
 .testimonials-quotes {
   width: 60px;
   margin-bottom: 30px;
   font-size: 45px;
 }
 
 .testimonials-control {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   /* top: 100px; */
 }
 
 .testimonials-control i {
   margin-bottom: 30px;
   
 }
 
 .testimonials-control span {
   margin-bottom: 15px;
 }
 
 .testimonials-content{
   box-shadow: 0px 0px 1px 0px #000;
 }
 .testimonials-text {
   padding-top: 5px;
   display: none;
   border-radius: 5px;
   overflow: hidden;
   background: #fff;
   padding-left: 50px;
 }
 
 .testimonials-text p {
   margin-top: 0 !important;
   padding-top: 0 !important;
 }
 
 span {
   display: block;
   margin-bottom: -5px;
 }
 
 span.testimonial-name {
   font-size: 18px;
   font-weight: 700;
   color: #ff8b29;
 }
 
 span.testimonial-position-held {
   font-style: italic;
   font-size: 18px;
   color: #949391;
 }
 
 .testimonials-animate-bottom {
   position: relative;
   animation: testimonials-animatebottom 0.4s;
 }
 @keyframes testimonials-animatebottom {
   from {
     bottom: -300px;
     opacity: 0;
   }
   to {
     bottom: 0;
     opacity: 1;
   }
 }
 
 .testimonials-animate-fading {
   animation: testimonials-fading 10s infinite;
 }
 @keyframes testimonials-fading {
   0% {
     opacity: 0;
   }
   50% {
     opacity: 1;
   }
   100% {
     opacity: 0;
   }
 }
 .testimonials-animate-opacity {
   animation: testimonials-opac 0.8s;
 }
 @keyframes testimonials-opac {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 
 .page7{
   height: 500px;
   background: #1c1a1aed;
 }
 .test{
   height: 300px;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 
 
 
 