@import url("//fonts.googleapis.com/css?family=Pacifico&text=Welcome");
@import url("//fonts.googleapis.com/css?family=Roboto:700&text=TO REALTHINK TECHNOLOGY");
@import url("//fonts.googleapis.com/css?family=Kaushan+Script&text=!");

body {
  min-height: 450px;
  height: 100vh;
  margin: 0;
  background: radial-gradient(circle, #0077ea, #1f4f96, #1b2949, #000);
}

/* first animation css */

.criterion {
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0;
  width: 0;
  transform: translate(-20px, -20px);
}

/*
  * background
  */
.background {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 0;
  -webkit-animation: background-animation 2s ease-in-out 4s 1 normal forwards;
  animation: background-animation 2s ease-in-out 4s 1 normal forwards;
}

.background0 {
  left: 0%;
  height: 100vh;
  background-color: #eb4747;
}

.background1 {
  left: 12.5%;
  height: 100vh;
  background-color: #ebc247;
}

.background2 {
  left: 25%;
  height: 100vh;
  background-color: #99eb47;
}

.background3 {
  left: 37.5%;
  height: 100vh;
  background-color: #47eb70;
}

.background4 {
  left: 50%;
  height: 100vh;
  background-color: #47ebeb;
}

.background5 {
  left: 62.5%;
  height: 100vh;
  background-color: #4770eb;
}

.background6 {
  left: 75%;
  height: 100vh;
  background-color: #9947eb;
}

.background7 {
  left: 87.5%;
  height: 100vh;
  background-color: #eb47c2;
}

.background8 {
  left: 100%;
  height: 100vh;
  background-color: #eb4747;
}

/*
  * text
  */
.text {
  position: absolute;
  width: 40px;
  line-height: 40px;
  opacity: 0;
  overflow: hidden;
}

.text::after {
  z-index: -1;
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 40px;
}

.text0 {
  left: -140px;
  top: 0;
  -webkit-animation: text-animation0 1s ease-in-out 1s 1 normal forwards, text2-animation0 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation0 1s ease-in-out 1s 1 normal forwards, text2-animation0 2s ease-in-out 5s 1 normal forwards;
}

.text0::after {
  -webkit-animation: text-after-animation0 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation0 2s ease-in-out 3s 1 normal forwards;
}

.text1 {
  left: -100px;
  top: 0;
  -webkit-animation: text-animation1 1s ease-in-out 1.2s 1 normal forwards, text2-animation1 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation1 1s ease-in-out 1.2s 1 normal forwards, text2-animation1 2s ease-in-out 5s 1 normal forwards;
}

.text1::after {
  -webkit-animation: text-after-animation1 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation1 2s ease-in-out 3s 1 normal forwards;
}

.text2 {
  left: -60px;
  top: 0;
  -webkit-animation: text-animation2 1s ease-in-out 1.4s 1 normal forwards, text2-animation2 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation2 1s ease-in-out 1.4s 1 normal forwards, text2-animation2 2s ease-in-out 5s 1 normal forwards;
}

.text2::after {
  -webkit-animation: text-after-animation2 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation2 2s ease-in-out 3s 1 normal forwards;
}

.text3 {
  left: -20px;
  top: 0;
  -webkit-animation: text-animation3 1s ease-in-out 1.6s 1 normal forwards, text2-animation3 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation3 1s ease-in-out 1.6s 1 normal forwards, text2-animation3 2s ease-in-out 5s 1 normal forwards;
}

.text3::after {
  -webkit-animation: text-after-animation3 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation3 2s ease-in-out 3s 1 normal forwards;
}

.text4 {
  left: 20px;
  top: 0;
  -webkit-animation: text-animation4 1s ease-in-out 1.8s 1 normal forwards, text2-animation4 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation4 1s ease-in-out 1.8s 1 normal forwards, text2-animation4 2s ease-in-out 5s 1 normal forwards;
}

.text4::after {
  -webkit-animation: text-after-animation4 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation4 2s ease-in-out 3s 1 normal forwards;
}

.text5 {
  left: 60px;
  top: 0;
  -webkit-animation: text-animation5 1s ease-in-out 2s 1 normal forwards, text2-animation5 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation5 1s ease-in-out 2s 1 normal forwards, text2-animation5 2s ease-in-out 5s 1 normal forwards;
}

.text5::after {
  -webkit-animation: text-after-animation5 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation5 2s ease-in-out 3s 1 normal forwards;
}

.text6 {
  left: 100px;
  top: 0;
  -webkit-animation: text-animation6 1s ease-in-out 2.2s 1 normal forwards, text2-animation6 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation6 1s ease-in-out 2.2s 1 normal forwards, text2-animation6 2s ease-in-out 5s 1 normal forwards;
}

.text6::after {
  -webkit-animation: text-after-animation6 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation6 2s ease-in-out 3s 1 normal forwards;
}

.text7 {
  left: 140px;
  top: 0;
  -webkit-animation: text-animation7 1s ease-in-out 2.4s 1 normal forwards, text2-animation7 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation7 1s ease-in-out 2.4s 1 normal forwards, text2-animation7 2s ease-in-out 5s 1 normal forwards;
}

.text7::after {
  -webkit-animation: text-after-animation7 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation7 2s ease-in-out 3s 1 normal forwards;
}

.text8 {
  left: 180px;
  top: 0;
  -webkit-animation: text-animation8 1s ease-in-out 2.6s 1 normal forwards, text2-animation8 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation8 1s ease-in-out 2.6s 1 normal forwards, text2-animation8 2s ease-in-out 5s 1 normal forwards;
}

.text8::after {
  -webkit-animation: text-after-animation8 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation8 2s ease-in-out 3s 1 normal forwards;
}

@-webkit-keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-animation8 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes text-animation8 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    transform: scale(3, 3);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #ebc247;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #ebc247;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #99eb47;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #99eb47;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #47eb70;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #47eb70;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #47ebeb;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #47ebeb;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #4770eb;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #4770eb;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #9947eb;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #9947eb;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #eb47c2;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #eb47c2;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text-after-animation8 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@keyframes text-after-animation8 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }

  50% {
    width: 40px;
    opacity: 1;
  }

  100% {
    left: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation0 {
  0% {
    left: -140px;
    opacity: 1;
  }

  50% {
    left: -100px;
    opacity: 0;
  }

  100% {
    left: -100px;
    opacity: 0;
  }
}

@keyframes text2-animation0 {
  0% {
    left: -140px;
    opacity: 1;
  }

  50% {
    left: -100px;
    opacity: 0;
  }

  100% {
    left: -100px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation1 {
  0% {
    left: -100px;
    opacity: 1;
  }

  50% {
    left: -60px;
    opacity: 0;
  }

  100% {
    left: -60px;
    opacity: 0;
  }
}

@keyframes text2-animation1 {
  0% {
    left: -100px;
    opacity: 1;
  }

  50% {
    left: -60px;
    opacity: 0;
  }

  100% {
    left: -60px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation2 {
  0% {
    left: -60px;
    opacity: 1;
  }

  50% {
    left: -20px;
    opacity: 0;
  }

  100% {
    left: -20px;
    opacity: 0;
  }
}

@keyframes text2-animation2 {
  0% {
    left: -60px;
    opacity: 1;
  }

  50% {
    left: -20px;
    opacity: 0;
  }

  100% {
    left: -20px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation3 {
  0% {
    left: -20px;
    opacity: 1;
  }

  50% {
    left: 20px;
    opacity: 0;
  }

  100% {
    left: 20px;
    opacity: 0;
  }
}

@keyframes text2-animation3 {
  0% {
    left: -20px;
    opacity: 1;
  }

  50% {
    left: 20px;
    opacity: 0;
  }

  100% {
    left: 20px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation4 {
  0% {
    left: 20px;
    opacity: 1;
  }

  50% {
    left: 60px;
    opacity: 0;
  }

  100% {
    left: 60px;
    opacity: 0;
  }
}

@keyframes text2-animation4 {
  0% {
    left: 20px;
    opacity: 1;
  }

  50% {
    left: 60px;
    opacity: 0;
  }

  100% {
    left: 60px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation5 {
  0% {
    left: 60px;
    opacity: 1;
  }

  50% {
    left: 100px;
    opacity: 0;
  }

  100% {
    left: 100px;
    opacity: 0;
  }
}

@keyframes text2-animation5 {
  0% {
    left: 60px;
    opacity: 1;
  }

  50% {
    left: 100px;
    opacity: 0;
  }

  100% {
    left: 100px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation6 {
  0% {
    left: 100px;
    opacity: 1;
  }

  50% {
    left: 140px;
    opacity: 0;
  }

  100% {
    left: 140px;
    opacity: 0;
  }
}

@keyframes text2-animation6 {
  0% {
    left: 100px;
    opacity: 1;
  }

  50% {
    left: 140px;
    opacity: 0;
  }

  100% {
    left: 140px;
    opacity: 0;
  }
}

@-webkit-keyframes text2-animation7 {
  0% {
    left: 140px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }

  50% {
    left: 180px;
    opacity: 1;
    transform: scale(1, 1);
  }

  65% {
    top: 0;
    transform: scale(1, 1);
  }

  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }

  75% {
    left: 180px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }

  85% {
    left: 180px;
  }

  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}

@keyframes text2-animation7 {
  0% {
    left: 140px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }

  50% {
    left: 180px;
    opacity: 1;
    transform: scale(1, 1);
  }

  65% {
    top: 0;
    transform: scale(1, 1);
  }

  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }

  75% {
    left: 180px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }

  85% {
    left: 180px;
  }

  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}

@-webkit-keyframes text2-animation8 {
  0% {
    left: 180px;
    opacity: 1;
  }

  50% {
    left: 220px;
    opacity: 0;
  }

  100% {
    left: 220px;
    opacity: 0;
  }
}

@keyframes text2-animation8 {
  0% {
    left: 180px;
    opacity: 1;
  }

  50% {
    left: 220px;
    opacity: 0;
  }

  100% {
    left: 220px;
    opacity: 0;
  }
}

/*
  * frame
  */
.frame {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0;
}

.frame0 {
  left: -140px;
  top: 0;
  -webkit-animation: frame-animation0 1s ease-in-out 0ms 1 normal forwards;
  animation: frame-animation0 1s ease-in-out 0ms 1 normal forwards;
  background-color: #eb4747;
}

.frame1 {
  left: -100px;
  top: 0;
  -webkit-animation: frame-animation1 1s ease-in-out 200ms 1 normal forwards;
  animation: frame-animation1 1s ease-in-out 200ms 1 normal forwards;
  background-color: #ebc247;
}

.frame2 {
  left: -60px;
  top: 0;
  -webkit-animation: frame-animation2 1s ease-in-out 400ms 1 normal forwards;
  animation: frame-animation2 1s ease-in-out 400ms 1 normal forwards;
  background-color: #99eb47;
}

.frame3 {
  left: -20px;
  top: 0;
  -webkit-animation: frame-animation3 1s ease-in-out 600ms 1 normal forwards;
  animation: frame-animation3 1s ease-in-out 600ms 1 normal forwards;
  background-color: #47eb70;
}

.frame4 {
  left: 20px;
  top: 0;
  -webkit-animation: frame-animation4 1s ease-in-out 800ms 1 normal forwards;
  animation: frame-animation4 1s ease-in-out 800ms 1 normal forwards;
  background-color: #47ebeb;
}

.frame5 {
  left: 60px;
  top: 0;
  -webkit-animation: frame-animation5 1s ease-in-out 1000ms 1 normal forwards;
  animation: frame-animation5 1s ease-in-out 1000ms 1 normal forwards;
  background-color: #4770eb;
}

.frame6 {
  left: 100px;
  top: 0;
  -webkit-animation: frame-animation6 1s ease-in-out 1200ms 1 normal forwards;
  animation: frame-animation6 1s ease-in-out 1200ms 1 normal forwards;
  background-color: #9947eb;
}

.frame7 {
  left: 140px;
  top: 0;
  -webkit-animation: frame-animation7 1s ease-in-out 1400ms 1 normal forwards;
  animation: frame-animation7 1s ease-in-out 1400ms 1 normal forwards;
  background-color: #eb47c2;
}

.frame8 {
  left: 180px;
  top: 0;
  -webkit-animation: frame-animation8 1s ease-in-out 1600ms 1 normal forwards;
  animation: frame-animation8 1s ease-in-out 1600ms 1 normal forwards;
  background-color: #eb4747;
}

@-webkit-keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@-webkit-keyframes frame-animation8 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes frame-animation8 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

/*
  * particle
  */
.particle {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.particle00 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation00 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation00 1s ease-in-out 1s 1 normal forwards;
}

.particle01 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation01 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation01 1s ease-in-out 1s 1 normal forwards;
}

.particle02 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation02 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation02 1s ease-in-out 1s 1 normal forwards;
}

.particle03 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation03 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation03 1s ease-in-out 1s 1 normal forwards;
}

.particle04 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation04 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation04 1s ease-in-out 1s 1 normal forwards;
}

.particle05 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation05 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation05 1s ease-in-out 1s 1 normal forwards;
}

.particle06 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation06 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation06 1s ease-in-out 1s 1 normal forwards;
}

.particle07 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation07 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation07 1s ease-in-out 1s 1 normal forwards;
}

.particle08 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation08 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation08 1s ease-in-out 1s 1 normal forwards;
}

.particle09 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation09 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation09 1s ease-in-out 1s 1 normal forwards;
}

.particle010 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation010 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation010 1s ease-in-out 1s 1 normal forwards;
}

.particle011 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation011 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation011 1s ease-in-out 1s 1 normal forwards;
}

.particle012 {
  left: -140px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation012 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation012 1s ease-in-out 1s 1 normal forwards;
}

.particle10 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation10 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation10 1s ease-in-out 1.2s 1 normal forwards;
}

.particle11 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation11 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation11 1s ease-in-out 1.2s 1 normal forwards;
}

.particle12 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation12 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation12 1s ease-in-out 1.2s 1 normal forwards;
}

.particle13 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation13 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation13 1s ease-in-out 1.2s 1 normal forwards;
}

.particle14 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation14 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation14 1s ease-in-out 1.2s 1 normal forwards;
}

.particle15 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation15 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation15 1s ease-in-out 1.2s 1 normal forwards;
}

.particle16 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation16 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation16 1s ease-in-out 1.2s 1 normal forwards;
}

.particle17 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation17 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation17 1s ease-in-out 1.2s 1 normal forwards;
}

.particle18 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation18 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation18 1s ease-in-out 1.2s 1 normal forwards;
}

.particle19 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation19 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation19 1s ease-in-out 1.2s 1 normal forwards;
}

.particle110 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation110 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation110 1s ease-in-out 1.2s 1 normal forwards;
}

.particle111 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation111 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation111 1s ease-in-out 1.2s 1 normal forwards;
}

.particle112 {
  left: -100px;
  opacity: 0;
  background-color: #ebc247;
  -webkit-animation: particle-animation112 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation112 1s ease-in-out 1.2s 1 normal forwards;
}

.particle20 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation20 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation20 1s ease-in-out 1.4s 1 normal forwards;
}

.particle21 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation21 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation21 1s ease-in-out 1.4s 1 normal forwards;
}

.particle22 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation22 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation22 1s ease-in-out 1.4s 1 normal forwards;
}

.particle23 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation23 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation23 1s ease-in-out 1.4s 1 normal forwards;
}

.particle24 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation24 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation24 1s ease-in-out 1.4s 1 normal forwards;
}

.particle25 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation25 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation25 1s ease-in-out 1.4s 1 normal forwards;
}

.particle26 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation26 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation26 1s ease-in-out 1.4s 1 normal forwards;
}

.particle27 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation27 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation27 1s ease-in-out 1.4s 1 normal forwards;
}

.particle28 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation28 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation28 1s ease-in-out 1.4s 1 normal forwards;
}

.particle29 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation29 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation29 1s ease-in-out 1.4s 1 normal forwards;
}

.particle210 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation210 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation210 1s ease-in-out 1.4s 1 normal forwards;
}

.particle211 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation211 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation211 1s ease-in-out 1.4s 1 normal forwards;
}

.particle212 {
  left: -60px;
  opacity: 0;
  background-color: #99eb47;
  -webkit-animation: particle-animation212 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation212 1s ease-in-out 1.4s 1 normal forwards;
}

.particle30 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation30 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation30 1s ease-in-out 1.6s 1 normal forwards;
}

.particle31 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation31 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation31 1s ease-in-out 1.6s 1 normal forwards;
}

.particle32 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation32 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation32 1s ease-in-out 1.6s 1 normal forwards;
}

.particle33 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation33 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation33 1s ease-in-out 1.6s 1 normal forwards;
}

.particle34 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation34 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation34 1s ease-in-out 1.6s 1 normal forwards;
}

.particle35 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation35 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation35 1s ease-in-out 1.6s 1 normal forwards;
}

.particle36 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation36 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation36 1s ease-in-out 1.6s 1 normal forwards;
}

.particle37 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation37 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation37 1s ease-in-out 1.6s 1 normal forwards;
}

.particle38 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation38 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation38 1s ease-in-out 1.6s 1 normal forwards;
}

.particle39 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation39 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation39 1s ease-in-out 1.6s 1 normal forwards;
}

.particle310 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation310 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation310 1s ease-in-out 1.6s 1 normal forwards;
}

.particle311 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation311 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation311 1s ease-in-out 1.6s 1 normal forwards;
}

.particle312 {
  left: -20px;
  opacity: 0;
  background-color: #47eb70;
  -webkit-animation: particle-animation312 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation312 1s ease-in-out 1.6s 1 normal forwards;
}

.particle40 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation40 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation40 1s ease-in-out 1.8s 1 normal forwards;
}

.particle41 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation41 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation41 1s ease-in-out 1.8s 1 normal forwards;
}

.particle42 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation42 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation42 1s ease-in-out 1.8s 1 normal forwards;
}

.particle43 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation43 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation43 1s ease-in-out 1.8s 1 normal forwards;
}

.particle44 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation44 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation44 1s ease-in-out 1.8s 1 normal forwards;
}

.particle45 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation45 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation45 1s ease-in-out 1.8s 1 normal forwards;
}

.particle46 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation46 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation46 1s ease-in-out 1.8s 1 normal forwards;
}

.particle47 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation47 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation47 1s ease-in-out 1.8s 1 normal forwards;
}

.particle48 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation48 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation48 1s ease-in-out 1.8s 1 normal forwards;
}

.particle49 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation49 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation49 1s ease-in-out 1.8s 1 normal forwards;
}

.particle410 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation410 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation410 1s ease-in-out 1.8s 1 normal forwards;
}

.particle411 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation411 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation411 1s ease-in-out 1.8s 1 normal forwards;
}

.particle412 {
  left: 20px;
  opacity: 0;
  background-color: #47ebeb;
  -webkit-animation: particle-animation412 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation412 1s ease-in-out 1.8s 1 normal forwards;
}

.particle50 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation50 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation50 1s ease-in-out 2s 1 normal forwards;
}

.particle51 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation51 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation51 1s ease-in-out 2s 1 normal forwards;
}

.particle52 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation52 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation52 1s ease-in-out 2s 1 normal forwards;
}

.particle53 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation53 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation53 1s ease-in-out 2s 1 normal forwards;
}

.particle54 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation54 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation54 1s ease-in-out 2s 1 normal forwards;
}

.particle55 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation55 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation55 1s ease-in-out 2s 1 normal forwards;
}

.particle56 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation56 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation56 1s ease-in-out 2s 1 normal forwards;
}

.particle57 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation57 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation57 1s ease-in-out 2s 1 normal forwards;
}

.particle58 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation58 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation58 1s ease-in-out 2s 1 normal forwards;
}

.particle59 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation59 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation59 1s ease-in-out 2s 1 normal forwards;
}

.particle510 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation510 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation510 1s ease-in-out 2s 1 normal forwards;
}

.particle511 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation511 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation511 1s ease-in-out 2s 1 normal forwards;
}

.particle512 {
  left: 60px;
  opacity: 0;
  background-color: #4770eb;
  -webkit-animation: particle-animation512 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation512 1s ease-in-out 2s 1 normal forwards;
}

.particle60 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation60 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation60 1s ease-in-out 2.2s 1 normal forwards;
}

.particle61 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation61 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation61 1s ease-in-out 2.2s 1 normal forwards;
}

.particle62 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation62 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation62 1s ease-in-out 2.2s 1 normal forwards;
}

.particle63 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation63 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation63 1s ease-in-out 2.2s 1 normal forwards;
}

.particle64 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation64 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation64 1s ease-in-out 2.2s 1 normal forwards;
}

.particle65 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation65 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation65 1s ease-in-out 2.2s 1 normal forwards;
}

.particle66 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation66 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation66 1s ease-in-out 2.2s 1 normal forwards;
}

.particle67 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation67 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation67 1s ease-in-out 2.2s 1 normal forwards;
}

.particle68 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation68 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation68 1s ease-in-out 2.2s 1 normal forwards;
}

.particle69 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation69 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation69 1s ease-in-out 2.2s 1 normal forwards;
}

.particle610 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation610 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation610 1s ease-in-out 2.2s 1 normal forwards;
}

.particle611 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation611 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation611 1s ease-in-out 2.2s 1 normal forwards;
}

.particle612 {
  left: 100px;
  opacity: 0;
  background-color: #9947eb;
  -webkit-animation: particle-animation612 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation612 1s ease-in-out 2.2s 1 normal forwards;
}

.particle70 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation70 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation70 1s ease-in-out 2.4s 1 normal forwards;
}

.particle71 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation71 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation71 1s ease-in-out 2.4s 1 normal forwards;
}

.particle72 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation72 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation72 1s ease-in-out 2.4s 1 normal forwards;
}

.particle73 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation73 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation73 1s ease-in-out 2.4s 1 normal forwards;
}

.particle74 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation74 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation74 1s ease-in-out 2.4s 1 normal forwards;
}

.particle75 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation75 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation75 1s ease-in-out 2.4s 1 normal forwards;
}

.particle76 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation76 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation76 1s ease-in-out 2.4s 1 normal forwards;
}

.particle77 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation77 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation77 1s ease-in-out 2.4s 1 normal forwards;
}

.particle78 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation78 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation78 1s ease-in-out 2.4s 1 normal forwards;
}

.particle79 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation79 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation79 1s ease-in-out 2.4s 1 normal forwards;
}

.particle710 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation710 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation710 1s ease-in-out 2.4s 1 normal forwards;
}

.particle711 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation711 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation711 1s ease-in-out 2.4s 1 normal forwards;
}

.particle712 {
  left: 140px;
  opacity: 0;
  background-color: #eb47c2;
  -webkit-animation: particle-animation712 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation712 1s ease-in-out 2.4s 1 normal forwards;
}

.particle80 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation80 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation80 1s ease-in-out 2.6s 1 normal forwards;
}

.particle81 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation81 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation81 1s ease-in-out 2.6s 1 normal forwards;
}

.particle82 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation82 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation82 1s ease-in-out 2.6s 1 normal forwards;
}

.particle83 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation83 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation83 1s ease-in-out 2.6s 1 normal forwards;
}

.particle84 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation84 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation84 1s ease-in-out 2.6s 1 normal forwards;
}

.particle85 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation85 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation85 1s ease-in-out 2.6s 1 normal forwards;
}

.particle86 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation86 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation86 1s ease-in-out 2.6s 1 normal forwards;
}

.particle87 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation87 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation87 1s ease-in-out 2.6s 1 normal forwards;
}

.particle88 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation88 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation88 1s ease-in-out 2.6s 1 normal forwards;
}

.particle89 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation89 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation89 1s ease-in-out 2.6s 1 normal forwards;
}

.particle810 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation810 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation810 1s ease-in-out 2.6s 1 normal forwards;
}

.particle811 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation811 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation811 1s ease-in-out 2.6s 1 normal forwards;
}

.particle812 {
  left: 180px;
  opacity: 0;
  background-color: #eb4747;
  -webkit-animation: particle-animation812 1s ease-in-out 2.6s 1 normal forwards;
  animation: particle-animation812 1s ease-in-out 2.6s 1 normal forwards;
}

@-webkit-keyframes particle-animation00 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation00 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation01 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -53.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation01 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -53.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation02 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation02 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation03 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -140px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation03 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -140px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation04 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation04 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation05 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -226.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation05 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -226.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation06 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -240px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation06 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -240px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation07 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -226.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation07 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -226.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation08 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation08 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation09 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -140px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation09 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -140px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation010 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation010 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation011 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -53.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation011 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -53.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation012 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation012 {
  0% {
    left: -140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation10 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation10 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation11 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -13.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation11 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -13.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation12 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation12 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation13 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -100px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation13 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -100px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation14 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation14 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation15 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -186.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation15 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -186.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation16 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation16 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation17 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -186.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation17 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -186.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation18 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation18 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation19 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -100px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation19 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -100px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation110 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation110 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation111 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -13.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation111 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -13.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation112 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation112 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation20 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation20 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation21 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 26.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation21 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 26.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation22 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation22 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation23 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -60px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation23 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -60px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation24 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation24 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation25 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -146.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation25 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -146.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation26 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -160px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation26 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -160px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation27 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -146.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation27 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -146.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation28 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation28 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation29 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -60px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation29 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -60px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation210 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation210 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation211 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 26.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation211 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 26.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation212 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation212 {
  0% {
    left: -60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation30 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation30 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation31 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 66.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation31 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 66.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation32 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation32 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation33 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -20px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation33 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -20px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation34 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation34 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation35 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -106.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation35 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -106.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation36 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -120px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation36 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -120px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation37 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -106.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation37 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -106.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation38 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation38 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation39 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -20px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation39 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -20px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation310 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation310 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation311 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 66.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation311 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 66.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation312 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation312 {
  0% {
    left: -20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation40 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 120px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation40 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 120px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation41 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 106.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation41 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 106.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation42 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation42 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation43 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 20px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation43 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 20px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation44 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation44 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation45 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -66.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation45 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -66.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation46 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation46 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation47 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -66.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation47 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -66.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation48 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation48 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation49 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 20px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation49 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 20px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation410 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation410 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation411 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 106.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation411 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 106.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation412 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 120px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation412 {
  0% {
    left: 20px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 120px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation50 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 160px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation50 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 160px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation51 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 146.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation51 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 146.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation52 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation52 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation53 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 60px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation53 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 60px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation54 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation54 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation55 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -26.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation55 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -26.60254040000001px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation56 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation56 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation57 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -26.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation57 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: -26.60254040000001px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation58 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation58 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation59 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 60px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation59 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 60px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation510 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation510 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation511 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 146.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation511 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 146.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation512 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 160px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation512 {
  0% {
    left: 60px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 160px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation60 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation60 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation61 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 186.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation61 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 186.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation62 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation62 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation63 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 100px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation63 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 100px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation64 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation64 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation65 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 13.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation65 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 13.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation66 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation66 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation67 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 13.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation67 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 13.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation68 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation68 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation69 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 100px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation69 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 100px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation610 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation610 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation611 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 186.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation611 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 186.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation612 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation612 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation70 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 240px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation70 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 240px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation71 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 226.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation71 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 226.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation72 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation72 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation73 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 140px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation73 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 140px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation74 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation74 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation75 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 53.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation75 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 53.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation76 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation76 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 40px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation77 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 53.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation77 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 53.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation78 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation78 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation79 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 140px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation79 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 140px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation710 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation710 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation711 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 226.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation711 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 226.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation712 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 240px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation712 {
  0% {
    left: 140px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 240px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation80 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 280px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation80 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 280px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation81 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 266.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation81 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 266.6025404px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation82 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation82 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation83 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 180px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation83 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 180px;
    top: 100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation84 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation84 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation85 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 93.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation85 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 93.39745959999999px;
    top: 50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation86 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation86 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 80px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation87 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 93.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation87 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 93.39745959999999px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation88 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation88 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation89 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 180px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation89 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 180px;
    top: -100px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation810 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation810 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation811 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 266.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation811 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 266.6025404px;
    top: -50px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes particle-animation812 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 280px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@keyframes particle-animation812 {
  0% {
    left: 180px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }

  100% {
    left: 280px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}

@-webkit-keyframes background-animation {
  0% {
    width: 0;
  }

  50% {
    width: 12.5%;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 25%;
  }
}

@keyframes background-animation {
  0% {
    width: 0;
  }

  50% {
    width: 12.5%;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 25%;
  }
}


/* first animation css end */

.stars {
  color: gold !important;
}

.h-600 {
  height: 600px;
}

.about-sec h3 {

  color: #fff;
  font-size: 38px;
  padding-bottom: 10px;
}

.about-sec p {
  color: #fff;
}

.about-img img {
  border: 5px solid #555;
}


.card-body {
  color: #000;
  min-height: 315px;

}

.stage {

  height: 300px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 10%;
  perspective: 9999px;
  transform-style: preserve-3d;
}

.layer {
  width: 90%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: ಠ_ಠ 5s infinite alternate ease-in-out -7.5s;
  animation-fill-mode: forwards;
  transform: rotateY(40deg) rotateX(33deg) translateZ(0);
}

.layer:after {

  font: 50px/0.65 "Pacifico", "Kaushan Script", Futura, "Roboto", "Trebuchet MS", Helvetica, sans-serif;
  content: "WELCOME TO REALTHINK  TECHNOLOGY!";
  white-space: pre;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  color: whitesmoke;
  letter-spacing: -2px;
  text-shadow: 4px 0 10px rgba(0, 0, 0, 0.13);
}

.layer:nth-child(1):after {
  transform: translateZ(0px);
}

.layer:nth-child(2):after {
  transform: translateZ(-1.5px);
}

.layer:nth-child(3):after {
  transform: translateZ(-3px);
}

.layer:nth-child(4):after {
  transform: translateZ(-4.5px);
}

.layer:nth-child(5):after {
  transform: translateZ(-6px);
}

.layer:nth-child(6):after {
  transform: translateZ(-7.5px);
}

.layer:nth-child(7):after {
  transform: translateZ(-9px);
}

.layer:nth-child(8):after {
  transform: translateZ(-10.5px);
}

.layer:nth-child(9):after {
  transform: translateZ(-12px);
}

.layer:nth-child(10):after {
  transform: translateZ(-13.5px);
}

.layer:nth-child(11):after {
  transform: translateZ(-15px);
}

.layer:nth-child(12):after {
  transform: translateZ(-16.5px);
}

.layer:nth-child(13):after {
  transform: translateZ(-18px);
}

.layer:nth-child(14):after {
  transform: translateZ(-19.5px);
}

.layer:nth-child(15):after {
  transform: translateZ(-21px);
}

.layer:nth-child(16):after {
  transform: translateZ(-22.5px);
}

.layer:nth-child(17):after {
  transform: translateZ(-24px);
}

.layer:nth-child(18):after {
  transform: translateZ(-25.5px);
}

.layer:nth-child(19):after {
  transform: translateZ(-25px);
}

.layer:nth-child(20):after {
  transform: translateZ(-20.5px);
}

.layer:nth-child(n+10):after {
  -webkit-text-stroke: 3px rgba(0, 0, 0, 0.25);
}

.layer:nth-child(n+11):after {
  -webkit-text-stroke: 15px dodgerblue;
  text-shadow: 6px 0 6px #00366b, 5px 5px 5px #002951, 0 6px 6px #00366b;
}

.layer:nth-child(n+12):after {
  -webkit-text-stroke: 15px #0077ea;
}

.layer:last-child:after {
  -webkit-text-stroke: 17px rgba(0, 0, 0, 0.1);
}

.layer:first-child:after {
  color: #fff;
  text-shadow: none;
}

@keyframes ಠ_ಠ {
  100% {
    transform: rotateY(-40deg) rotateX(-43deg);
  }
}




.blck-bck {

  padding: 10px 0px 10px 0px;
  background-color: #000000;

}

.brand img {
  width: 100%;

}

.right-menu {
  padding: 10px 0px 10px 0px;
  float: right;
}

.right-menu a {
  text-decoration: none;
  color: #1f67b0;
  font-size: 17px;
  font-weight: 500;
  padding-left: 25px;
}

.title {
  margin-bottom: 20px;
  padding: 20px
}

.img {
  height: 260px;
  width: 100%
}

.commitment_service {
  box-shadow: 0px 0px 1px 0px #00000059;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
}

.main-section {
  padding: 50px 0px 50px 0px;

}


.btn_click {
  background-color: transparent;
  width: 40%;
  text-decoration: none;
  color: #0000007e;
  font-weight: 400;
  cursor: pointer;
}

/* ----------OUR-SERVICE-------------------------- */




.heading {
  text-align: center;
}

.bg-blue {
  text-align: center;
  background: linear-gradient(56deg, #d7f1fa62 0%, rgb#879ab881 100%);
  background-size: cover;
  margin: 0 auto;
  place-items: center;
  padding:50px 0px 0px 0px;
}

.section-title {
  margin-bottom: 38px;
  color: #fff;
  font-size: 45px
}

.services-p {
  color: #fff;
  padding-bottom: 30px;
}

.shadow,
.subscription-wrapper {
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1) !important
}


.icon-primary {
  color: #062caf
}

.icon-bg-circle {
  position: relative
}

.icon-lg {
  font-size: 50px
}

.icon-bg-circle::before {
  z-index: 1;
  position: relative
}

.icon-bg-primary::after {
  background: #062caf !important
}

.icon-bg-circle::after {
  content: '';
  position: absolute;
  width: 68px;
  height: 68px;
  top: -35px;
  left: 15px;
  border-radius: 50%;
  background: inherit;
  opacity: .1
}

.readmore {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
}

p,
.paragraph {
  margin-bottom: 0rem !important;
  font-weight: 400;
  color: #212529;
  font-size: 16px;
  line-height: 1.6;
  font-family: "Open Sans", sans-serif
}

.icon-bg-yellow::after {
  background: #f6a622 !important
}

.icon-bg-purple::after {
  background: #7952f5
}

.icon-yellow {
  color: #f6a622
}

.icon-purple {
  color: #7952f5
}

.icon-cyan {
  color: #02d0a1
}

.icon-bg-cyan::after {
  background: #02d0a1
}

.icon-bg-red::after {
  background: #ff4949
}

.icon-red {
  color: #ff4949
}

.icon-bg-green::after {
  background: #66cc33
}

.icon-green {
  color: #66cc33
}

.icon-bg-orange::after {
  background: #ff7c17
}

.icon-orange {
  color: #ff7c17
}

.icon-bg-blue::after {
  background: #3682ff
}

.icon-blue {
  color: #3682ff
}







.Yearly-timeline {
  font-family: 'Poppins', sans-serif;
}

.Yearly-timeline:after {
  content: '';
  display: block;
  clear: both;
}

.Yearly-timeline .timeline {
  width: calc(50% + 17px);
  padding: 0 30px 30px 0;
  float: left;
  position: relative;
}

.Yearly-timeline .timeline:before,
.Yearly-timeline .timeline:after {
  content: '';
  height: 60px;
  width: 2px;
  border-left: 2px solid #70d6ff;
  transform: rotate(-45deg);
  position: absolute;
  right: 30px;
  bottom: 0;
}

.Yearly-timeline .timeline:after {
  background-color: #fff;
  height: 20px;
  width: 20px;
  border: 6px solid #70d6ff;
  border-radius: 50%;
  right: 0;
}

.Yearly-timeline .timeline-content {
  text-decoration: none;
  color: #555;
  background: #70d6ff;
  text-align: center;
  min-height: 170px;
  padding: 30px 35px 35px;
  border-radius: 15px 15px 0 15px;
  display: block;
  position: relative;
  z-index: 1;
}

.Yearly-timeline .timeline-content:before {
  content: '';
  background-color: #fff;
  position: absolute;
  left: 17px;
  right: 17px;
  top: 17px;
  bottom: 17px;
  z-index: -1;
}

.Yearly-timeline .timeline-content:hover {
  text-decoration: none;
}

.Yearly-timeline .timeline-icon {
  color: #777;
  font-size: 35px;
}

.Yearly-timeline .title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 0 8px;
}

.Yearly-timeline .description {
  color: #777;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 18px;
  margin: 0;
}

.Yearly-timeline .timeline:nth-child(even) {
  padding: 0 0 30px 30px;
  float: right;
}

.Yearly-timeline .timeline:nth-child(even):before {
  transform: rotate(45deg);
  right: auto;
  left: 30px;
}

.Yearly-timeline .timeline:nth-child(even):after {
  right: auto;
  left: 0;
}

.Yearly-timeline .timeline:nth-child(even) .timeline-content {
  border-radius: 15px 15px 15px 0;
}

.Yearly-timeline .timeline:nth-child(4n+2):before,
.Yearly-timeline .timeline:nth-child(4n+2):after {
  border-color: #ff70a6;
}

.Yearly-timeline .timeline:nth-child(4n+2) .timeline-content {
  background: #ff70a6;
}

.Yearly-timeline .timeline:nth-child(4n+3):before,
.Yearly-timeline .timeline:nth-child(4n+3):after {
  border-color: #ff9770;
}

.Yearly-timeline .timeline:nth-child(4n+3) .timeline-content {
  background: #ff9770;
}

.Yearly-timeline .timeline:nth-child(4n+4):before,
.Yearly-timeline .timeline:nth-child(4n+4):after {
  border-color: #ffd670;
}

.Yearly-timeline .timeline:nth-child(4n+4) .timeline-content {
  background: #ffd670;
}

/* ---------- Start WHY-CHOOSE-US-------------------------- */

.heading-sec h3 {
  color: white;
  padding-bottom: 30px;
  padding-top: 30px;
}

.pragrap {
  color: white;
}

.text-sec {
  color: white;
  font-size: 17px;
}

.content-sec p {

  font-size: 13px;
  font-weight: 400;
  color: #c7c8ca;
}

.right-menu:hover a {
  color: #00366b;
}

/* =---Why =Choose--us------------ */
.heading-sec {
  text-align: center;
}

.cardbox {
  border: 1px dashed #dadada;
  text-align: center;
  padding: 15px;
  margin-top: 9px;
}

.cardbox h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.heading p {
  color: #fff;
}

.heading h3 {
  color: #fff;
  font-size: 38px;
}

.cardbox p {
  text-align: justify;
  color: #fff;
}




@media screen and (max-width:767px) {
  .Yearly-timeline .timeline {
    width: 100%;
    padding: 30px 0 0 30px;
    margin: 0 0 20px;
  }

  .Yearly-timeline .timeline:before,
  .Yearly-timeline .timeline:nth-child(even):before {
    transform: rotate(-45deg);
    bottom: auto;
    top: 0;
    right: auto;
    left: 30px;
  }

  .Yearly-timeline .timeline:after,
  .Yearly-timeline .timeline:nth-child(even):after {
    bottom: auto;
    top: 0;
    right: auto;
    left: 0;
  }

  .Yearly-timeline .timeline-content,
  .Yearly-timeline .timeline:nth-child(even) .timeline-content {
    border-radius: 0 15px 15px 15px;
  }
}

.contact-left {
  background: #062caf;
  padding: 30px;
  color: #fff;

}

.contact-right {
  background-color: #fff;
  padding: 5px;
}

/*----------------------Swipper-js---------- */
::selection {
  background: rgba(23, 162, 184, 0.3);
}

.img-card-q b {
  color: #fff;
}

.img-card-q p {
  color: #fff;
}

.box {
  background: #fff;
  padding: 25px;
  margin-bottom: 15px;
  border-radius: 3px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.box i.quote {
  font-size: 20px;
  color: #17a2b8;
}

.box .content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.box .info .name {
  font-weight: 600;
  font-size: 17px;
  color: #000;
}

.box .info .job {
  font-size: 16px;
  font-weight: 500;
  color: #17a2b8;
}

.box .info .stars {
  margin-top: 2px;
}

.box .info .stars i {
  color: #17a2b8;
}

.box .content .image {
  height: 75px;
  width: 75px;
  padding: 3px;
  background: #17a2b8;
  border-radius: 50%;
}

.content .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
}

.box:hover .content .image img {
  border-color: #fff;
}

/*---------- Blog ---------------------*/
.blog-home {

  background: #fff;
  border: 1px white;
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 25px;
  box-shadow: 0 4px 16px rgba(128, 125, 125, 0.1);
}

.blog-home button {
  border-style: none;
  color: rgb(5, 5, 254);
  font-size: 18px;
}

.vlog p {
  text-align: center;
  color: #fff;
}

.vlog h1 {
  text-align: center;
  color: #fff;
}

.contact-sec h3 {
  font-size: 25px;
}

.map_location {
  width: 100%;
}

.Social_icon>li>i {
  color: #fff;
  cursor: pointer;
}

.Social_icon .insta {
  background-color: rgb(225, 28, 28);
}

.Social_icon .youtub {
  background-color: #ff2d2d;
}

.Social_icon .twit {
  background-color: #000;
}

.banner_1 {
  height: 500;
}

.img-w {
  height: 300px;


}

.btn {
  margin-top: 20px;
  background-image: linear-gradient(90deg, rgb(101, 97, 166) 0%, rgb(127, 127, 234) 35%, rgb(0, 109, 131) 100%);
}

.btn .name_btn {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;

}


/* Responsive for mobile   */

@media only screen and (max-width: 500px) {

  .layer:after {

    font: 15px/0.65 "Pacifico", "Kaushan Script", Futura, "Roboto", "Trebuchet MS", Helvetica, sans-serif !important;


  }

  .card_none {
    display: none;
  }

  .img-w {

    background-color: #ffffff;
    position: relative;
    margin-bottom: 15px;
  }

  .banner_1 {
    height: 169px !important;
  }

}





@media only screen and (max-width: 991px) {

  .layer:after {

    font: 30px/0.65 "Pacifico", "Kaushan Script", Futura, "Roboto", "Trebuchet MS", Helvetica, sans-serif;

  }

  .card_none {
    display: none;
  }

  .img-w {

    background-color: #ffffff;
    position: relative;
    margin-bottom: 15px;
  }

  .about-sec {
    margin-bottom: 15px;
  }

}